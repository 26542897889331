

















































import BaseCard from "@/components/Base/BaseCard.vue";
import { toCurrency } from "@/helpers/Formatter";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
    },
})
export default class SharedDashboardStatistics extends Vue {
    @Prop() statistics!: any[];
    @Prop() loading!: boolean;

    statisticValue(statistic: any) {
        let value = statistic.value;

        if (statistic.type === "currency") value = toCurrency(value);
        if (statistic.suffix) value += " " + statistic.suffix;

        return value;
    }
}
